import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import mediaqueries from '@styles/media';

import { Icon } from '@types';

const ReactLogo = (props: any) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 600 600" {...props}>
      <path fill="none" d="M0 0h600v600H0z" />
      <circle fill="#00D8FF" cx={299.529} cy={299.628} r={50.167} />
      <path
        fill="none"
        stroke="#00D8FF"
        strokeWidth={24}
        strokeMiterlimit={10}
        d="M299.529 197.628c67.356 0 129.928 9.665 177.107 25.907 56.844 19.569 91.794 49.233 91.794 76.093 0 27.991-37.041 59.503-98.083 79.728-46.151 15.291-106.879 23.272-170.818 23.272-65.554 0-127.63-7.492-174.29-23.441-59.046-20.182-94.611-52.103-94.611-79.559 0-26.642 33.37-56.076 89.415-75.616 47.355-16.509 111.472-26.384 179.486-26.384z"
      />
      <path
        fill="none"
        stroke="#00D8FF"
        strokeWidth={24}
        strokeMiterlimit={10}
        d="M210.736 248.922c33.649-58.348 73.281-107.724 110.92-140.48 45.35-39.466 88.507-54.923 111.775-41.505 24.248 13.983 33.042 61.814 20.067 124.796-9.81 47.618-33.234 104.212-65.176 159.601-32.749 56.788-70.25 106.819-107.377 139.272-46.981 41.068-92.4 55.929-116.185 42.213-23.079-13.31-31.906-56.921-20.834-115.233 9.355-49.27 32.832-109.745 66.81-168.664z"
      />
      <path
        fill="none"
        stroke="#00D8FF"
        strokeWidth={24}
        strokeMiterlimit={10}
        d="M210.821 351.482c-33.746-58.292-56.731-117.287-66.312-166.255-11.544-58.999-3.382-104.109 19.864-117.566 24.224-14.024 70.055 2.244 118.14 44.94 36.356 32.28 73.688 80.837 105.723 136.173 32.844 56.733 57.461 114.209 67.036 162.582 12.117 61.213 2.309 107.984-21.453 121.74-23.057 13.348-65.249-.784-110.239-39.499-38.013-32.711-78.682-83.253-112.759-142.115z"
      />
    </svg>
  );
};

const SLLogo = (props: any) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="189.372 -20.807 121.256 191.615"
      style={{
        background: '0 0',
      }}
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <path
        stroke={props.fill}
        strokeWidth={4}
        d="M230.993 106.995q-4.42 0-8.1-1.47-3.68-1.47-5.82-4.32-2.15-2.85-2.15-6.75 0-6.08 3.65-10.5 1.09-1.34 2.37-2.56 1.28-1.21 2.05-1.21 1.21 0 1.21 1.09 0 .51-.25.89-3.2 2.88-4.58 5.57-1.38 2.69-1.38 5.82 0 5.38 3.81 8 3.81 2.63 9.51 2.63 3.64 0 6.43-1.89 2.78-1.89 4.29-5.09 1.5-3.2 1.5-6.91 0-5.63-5.18-17.6-5.19-12.1-5.19-16.83 0-4.93 2.37-8.19 2.37-3.27 6.78-3.27 3.4 0 5.67 1.7 2.27 1.69 3.36 3.84 1.09 2.14 1.09 3.49 0 .64-.55 1.12-.54.48-1.12.48-.96 0-1.15-1.16-.45-2.94-2.43-4.86-1.99-1.92-4.87-1.92t-4.48 2.02q-1.6 2.01-1.6 5.02 0 4.22 1.28 8.22 1.28 4 3.91 10.08 2.56 5.83 3.87 9.89 1.31 4.07 1.31 8.29 0 4.1-1.6 7.84t-5.12 6.14q-3.52 2.4-8.89 2.4zm43.52-.25q-7.94-.13-12.13-.26t-4.67-.22q-.48-.1-.61-.35-.32-.39-.32-1.09 0-1.35.96-2.69 1.34-2.11 3.13-9.86 1.8-7.74 4.23-19.77.19-.9 1.53-7.36 1.67-7.68 3.91-18.56.19-.64.77-1.09.57-.45 1.08-.45.9 0 1.09 1.09-1.41 6.14-4.03 19.71-1.54 8.07-4.32 20.8-2.78 12.74-3.81 16.19 6.85 1.16 11.07 1.16 4.74-.13 6.92-.26 2.17-.13 4.22-.38 1.09 0 1.09 1.08 0 .52-.45 1.09-.45.58-1.09.77-5.12.45-8.57.45z"
      />
    </svg>
  );
};

const Logo: Icon = ({ fill = 'white' }) => {
  return (
    <LogoContainer>
      <div className="header">isabel <span className="lastname">sanches</span></div>
    </LogoContainer>
  );
};

export default Logo;

const colors = p => {
return css`
  .header {
    font-family: 'Abril Fatface', cursive;
    font-size: 2em;
    color: ${p.theme.colors.primary};
    a {
      color: ${p.theme.colors.primary};
    }
    .lastname {
      color: ${p.theme.colors.grey};
    }
  }
`;
}

const LogoContainer = styled.div`
  @font-face {
    font-family: 'Abril Fatface';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Abril Fatface'), local('AbrilFatface-Regular'), url(https://fonts.gstatic.com/s/abrilfatface/v11/zOL64pLDlL1D99S8g8PtiKchq-dmjQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  ${colors}

  .Logo__Mobile {
    display: none;
  }

  

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
